import { getStore } from '../store/menuDefinitionStore';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { MenuDefinitionStoreName } from '../store/menuDefinitionStore';
import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

export const setMenuDefinition = mutatorAction(
    'setMenuDefinition',
    function setMenuDefinition(
        editorId: string,
        storeName: MenuDefinitionStoreName,
        menuDefinition: () => MenuDefinition
    ) {
        if (!getStore().menuDefinitionStoreMap.has(editorId)) {
            getStore().menuDefinitionStoreMap.set(editorId, {
                menuDefinitions: new ObservableMap<MenuDefinitionStoreName, () => MenuDefinition>(),
            });
        }
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        getStore()
            .menuDefinitionStoreMap.get(editorId)!
            .menuDefinitions.set(storeName, menuDefinition);
    }
);

export const clearMenuDefinition = mutatorAction(
    'clearMenuDefinition',
    function clearMenuDefinition(editorId: string) {
        const menuDefinitionStoreMap = getStore().menuDefinitionStoreMap;
        if (menuDefinitionStoreMap.has(editorId)) {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            menuDefinitionStoreMap.get(editorId)!.menuDefinitions.clear();
            menuDefinitionStoreMap.delete(editorId);
        }
    }
);

export const resetMenuDefinition = mutatorAction(
    'resetMenuDefinition',
    function resetMenuDefinition(editorId: string, storeName: MenuDefinitionStoreName) {
        const editorMenuStore = getStore().menuDefinitionStoreMap.get(editorId);
        if (editorMenuStore) {
            if (editorMenuStore.menuDefinitions.has(storeName)) {
                editorMenuStore.menuDefinitions.delete(storeName);
            }
            if (editorMenuStore.menuDefinitions.size == 0) {
                getStore().menuDefinitionStoreMap.delete(editorId);
            }
        }
    }
);
