import { type IEditor } from 'roosterjs-editor-types';
import { type ContentPosition } from 'roosterjs-editor-types/lib/enum/ContentPosition';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    type COPILOT_DRAFT_ENTITY_NAMES,
    COPILOT_REPLIES_ENTITY_ID,
    RemoveDraftCopilotEntityChangeSource,
    COPILOT_ADDIN_TITLE_ID,
} from './constants';
import getEntitySelector from 'roosterjs-editor-dom/lib/entity/getEntitySelector';
import { insertEntityCommand } from 'owa-editor-command';
import { getFuncFromEditorId } from './cacheEditorIdAndPluginFuncRef';
import { errorThatWillCauseAlert } from 'owa-trace';
import type { CopilotElaborateEntryPoint } from '../types/CopilotElaborateEntryPoint';

export type CopilotDraftEntityName = typeof COPILOT_DRAFT_ENTITY_NAMES[number];

const ClearHintNodeChangeSource = 'ClearHintNode';

export default function insertDraftCopilotEntity(
    editor: IEditor,
    entityName: CopilotDraftEntityName,
    editorId: string,
    addinTitleId?: string,
    entryPoint?: CopilotElaborateEntryPoint
) {
    if (isFeatureEnabled('mon-copilot-cmp-contentModelPlugin')) {
        const mountFunction = getFuncFromEditorId(editorId);
        if (!!mountFunction) {
            entryPoint
                ? mountFunction(entryPoint, addinTitleId)
                : mountFunction('Unknown', addinTitleId);
        } else {
            errorThatWillCauseAlert('Missing copilot mount function for editor instance.');
        }
    } else {
        insertDraftCopilotEntityInternal(editor, entityName, addinTitleId);
    }
}

function insertDraftCopilotEntityInternal(
    editor: IEditor,
    entityName: CopilotDraftEntityName,
    addinTitleId?: string
) {
    if (!sameCopilotEntityAlreadyExists(editor, entityName)) {
        // Remove hint node if any before we insert copilot to avoid inserting copilot under an entity delimiter node
        // This is a temporary fix. Ideally we should use Content Model insertEntity API here
        editor.triggerContentChangedEvent(ClearHintNodeChangeSource);
        editor.triggerContentChangedEvent(RemoveDraftCopilotEntityChangeSource);

        const doc = editor.getDocument();
        const targetDiv = doc.createElement('div');
        targetDiv.setAttribute(COPILOT_REPLIES_ENTITY_ID, 'true');
        addinTitleId &&
            addinTitleId != 'Outlook' &&
            targetDiv.setAttribute(COPILOT_ADDIN_TITLE_ID, addinTitleId);
        targetDiv.setAttribute('contenteditable', 'false');

        insertEntityCommand(
            editor,
            entityName,
            targetDiv,
            true /*isBlock*/,
            true /* isReadOnly */,
            0
        );
    }
}

function sameCopilotEntityAlreadyExists(
    editor: IEditor,
    entityName: CopilotDraftEntityName
): boolean {
    if (entityName === 'DraftCopilot' || entityName.indexOf('DraftCopilotPreinsert') >= 0) {
        return editor.queryElements(getEntitySelector('DraftCopilot')).length > 0;
    } else if (entityName === 'CopilotCoach') {
        return editor.queryElements(getEntitySelector('CopilotCoach')).length > 0;
    } else {
        return false;
    }
}
