import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import ClipboardPaste from 'owa-fluent-icons-svg/lib/icons/ClipboardPasteRegular';
import ClipboardLetter from 'owa-fluent-icons-svg/lib/icons/ClipboardLetterRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import extractClipboardItems from 'roosterjs-editor-dom/lib/clipboard/extractClipboardItems';
import { type ContentTypePrefix } from 'roosterjs-editor-types/lib/enum/ContentType';
import { pasteText, pastePlainText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import type RibbonCutCopyPasteInfo from 'owa-compose-formatcontrols-sections/lib/schema/RibbonCutCopyPasteInfo';
import { lazyShowEditorPasteWarningDialog } from 'owa-editor-ribbon-secondary-ui';
import { pasteCommand } from 'owa-editor-command';
import { isBrowserFirefox, isBrowserSafari } from 'owa-user-agent/lib/userAgent';
import { lazyOperateContent } from 'owa-editor';
import type { ClipboardData } from 'roosterjs-editor-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export enum PasteType {
    Text,
    Image,
    Normal,
    MergeFormat,
}

const COMPLEX_CONTENT_REGEX = /<table ?(.|\n)*>/i;
const permissionQuery = {
    name: 'clipboard-read' as PermissionName,
    allowWithoutGesture: true,
} as PermissionDescriptor;
const DENIED = 'denied';

function isChromeClipboard(clipboard: Clipboard): clipboard is Clipboard {
    return !!(<Clipboard>clipboard)?.read;
}

// export only for testing
export function createDataTransferItem(
    kind: 'string' | 'file',
    type: string,
    blob: Blob
): DataTransferItem {
    const file = new File([blob], 'paste.png', { type, lastModified: new Date().getDate() });
    return {
        kind,
        type,
        getAsFile: () => file,
        getAsString: (callback: FunctionStringCallback) => {
            blob.text().then(text => callback(text));
        },
        webkitGetAsEntry: () => null,
    };
}

export const pasteOperation = async (
    editorId: string,
    pasteType: PasteType,
    retrieverKey: string | undefined,
    retriever: (key: string) => RibbonCutCopyPasteInfo | null
) => {
    const { editorViewState, targetWindow, isPlainTextEditor } =
        (retrieverKey && retriever(retrieverKey)) || {};

    try {
        const navigator = targetWindow?.navigator;
        const permissionStatus =
            isBrowserSafari() || isBrowserFirefox()
                ? undefined
                : await navigator?.permissions.query(permissionQuery);
        const clipboard = navigator?.clipboard;
        if (permissionStatus?.state == DENIED || !clipboard || !isChromeClipboard(clipboard)) {
            //paste is unsupported on Firefox, show warning
            lazyShowEditorPasteWarningDialog.importAndExecute(targetWindow || window);
            return;
        }

        if (editorViewState) {
            let clipboardData: ClipboardData;
            let copiedText: string;
            if (isPlainTextEditor) {
                copiedText = await clipboard.readText();
            } else {
                const clipboardDataList = await clipboard.read();
                const promises: Promise<DataTransferItem>[] = [];

                clipboardDataList?.forEach(item => {
                    item?.types?.forEach(type => {
                        const isText = type?.indexOf('text/') == 0;
                        const isFile = type?.indexOf('image/') == 0;

                        if (isText || isFile) {
                            promises.push(
                                item
                                    .getType(type)
                                    .then(blob =>
                                        createDataTransferItem(
                                            isText ? 'string' : 'file',
                                            type,
                                            blob
                                        )
                                    )
                            );
                        }
                    });
                });

                const values = await Promise.all(promises);
                clipboardData = await extractClipboardItems(values);
            }

            lazyOperateContent.import().then(operateContent => {
                operateContent(
                    editorViewState,
                    (editor, range) => {
                        editor.focus();
                        if (range) {
                            editor.select(range);
                        }
                        const asPlainText = pasteType == PasteType.Text;
                        const asImage = pasteType == PasteType.Image;
                        const mergeFormat = pasteType == PasteType.MergeFormat;

                        const pasteCallback = () =>
                            pasteCommand(
                                editorId,
                                clipboardData,
                                asPlainText,
                                mergeFormat,
                                asImage
                            );

                        if (
                            pasteType == PasteType.Normal &&
                            clipboardData.rawHtml &&
                            COMPLEX_CONTENT_REGEX.test(clipboardData.rawHtml) &&
                            targetWindow
                        ) {
                            // If the clipboard contains complex content, show a warning dialog
                            lazyShowEditorPasteWarningDialog.importAndExecute(
                                targetWindow,
                                pasteCallback
                            );
                        } else {
                            pasteCallback();
                        }
                        return null;
                    },
                    (content, selectionStart, selectionEnd) => {
                        const newSelectionStart = selectionStart + copiedText.length;
                        const newSelectionEnd = selectionStart + copiedText.length;
                        return {
                            value:
                                content.substring(0, selectionEnd) +
                                copiedText +
                                content.substring(selectionEnd),
                            selectionStart: newSelectionStart,
                            selectionEnd: newSelectionEnd,
                            focus: true,
                        };
                    }
                );
            });
        }
    } catch {}
};

export const getPasteControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        asPlainText: boolean,
        retrieverKey: string | undefined,
        retriever: (key: string) => RibbonCutCopyPasteInfo | null,
        iconColor: string | undefined
    ): RibbonControlProps => {
        const label = asPlainText ? pastePlainText : pasteText;
        return createAppButton(
            getComposeRibbonId(6011, editorId) + (asPlainText ? '_plain' : ''),
            loc(label),
            getComputedCallback(
                6011,
                pasteOperation,
                editorId,
                asPlainText
                    ? PasteType.Text
                    : isFeatureEnabled('cmp-default-merge-format-paste')
                    ? PasteType.MergeFormat
                    : PasteType.Normal,
                retrieverKey,
                retriever
            ),
            tabId ? constructKeytip([tabId], 6011) : undefined,
            asPlainText ? ClipboardLetter : ClipboardPaste,
            iconColor,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    label,
                    undefined /* description */,
                    getHoverTooltipHotkey(6011)
                ),
            }
        );
    }
);
