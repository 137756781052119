export const boldText = "OVddbd";
export const italicText = "hXB$Rb";
export const underlineText = "TY26Wc";
export const bulletsText = "k_vitd";
export const numberingText = "U0KZAc";
export const outdentText = "en6qU";
export const indentText = "lLaz7b";
export const blockQuoteText = "Q7YXB";
export const alignText = "Yac56";
export const superscriptText = "hIEBid";
export const subscriptText = "_wdVae";
export const strikethroughText = "m_nvnc";
export const dirLtrText = "cCqy2d";
export const dirRtlText = "ISzTib";
export const undoText = "v643M";
export const redoText = "bFmSqd";
export const formatPainterText = "r4XLNc";
export const changeCaseText = "J$IPG";
export const fontColorText = "Pe0zlb";
export const moreColorsText = "uDza9";
export const noColorCell = "cJHuDc";
export const highlightColorText = "uStZpb";
export const pasteText = "kbyxid";
export const pastePlainText = "sRJHZc";
export const fontText = "UvD22c";
export const fontSizeText = "JNOqF";
export const autoFormatSettings = "DYxsib";
export const stylePickerText = "Sa01ne";
export const spacingText = "MFgwme";
export const pasteAsImage = "A6ZyMe";
export default {boldText,italicText,underlineText,bulletsText,numberingText,outdentText,indentText,blockQuoteText,alignText,superscriptText,subscriptText,strikethroughText,dirLtrText,dirRtlText,undoText,redoText,formatPainterText,changeCaseText,fontColorText,moreColorsText,noColorCell,highlightColorText,pasteText,pastePlainText,fontText,fontSizeText,autoFormatSettings,stylePickerText,spacingText,pasteAsImage};