import type {
    IButtonStyles,
    IContextualMenuClassNames,
    IContextualMenuStyles,
} from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import TextEditStyleRegular from 'owa-fluent-icons-svg/lib/icons/TextEditStyleRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { stopLivePreview } from 'owa-compose-formatcontrols-sections/lib/utils/livePreviewHandler';
import { stylePickerTitle } from './stylePicker.scss';
import { stylePickerText } from './formatControlStrings.locstring.json';
import { styleDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onStyleMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getStylePickerControl = owaComputedFn(
    (
        editorId: string,
        isBlockQuote: boolean,
        headerLevel: number,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ) => {
        const anchorId = getComposeRibbonId(6073, editorId);

        const selectedTag = isBlockQuote
            ? 'Quote'
            : headerLevel >= 1 && headerLevel <= 3
            ? `heading ${headerLevel}`
            : 'normal';
        const storeName = 'styleMenuDefinitionStore';
        return createAppFlyoutAnchor(
            anchorId,
            loc(stylePickerText),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6073, true /* hasMenu */) : undefined,
            TextEditStyleRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: tabId ? getHoverTooltip(stylePickerText, styleDesc) : undefined,
                disabled: isDisabled,
                onAfterMenuDismiss: () => {
                    stopLivePreview(editorId);
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
                menuClassNames: <IContextualMenuClassNames>{
                    header: stylePickerTitle,
                    subComponentStyles: {
                        callout: <IContextualMenuStyles>{
                            root: {
                                borderRadius: '4px',
                                overflow: 'hidden',
                            },
                        },
                        menuItem: {},
                    },
                },
                onExecute: () => {
                    onStyleMenuClicked(anchorId, editorId, selectedTag, tabId, source);
                },
            }
        );
    }
);
