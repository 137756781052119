export const undoTextCommand = "UcYDLd";
export const redoCommand = "Tax76b";
export const insertLinkCommand = "OiwQrc";
export const pasteCommand = "WmGIbe";
export const cutCommand = "XeRBIc";
export const copyCommand = "Io21Xc";
export const boldCommand = "i9EYAc";
export const italicCommand = "zZcFJd";
export const underlineCommand = "ZsCBQb";
export const bulletsCommand = "S1PFWc";
export const numberingCommand = "Op6IJb";
export const clearFormatCommand = "dM7ME";
export const headingOneCommand = "NqDfOc";
export const headingTwoCommand = "ri4D1d";
export const headingThreeCommand = "xKeej";
export const dictationToggleCommand = "KUL_Yd";
export default {undoTextCommand,redoCommand,insertLinkCommand,pasteCommand,cutCommand,copyCommand,boldCommand,italicCommand,underlineCommand,bulletsCommand,numberingCommand,clearFormatCommand,headingOneCommand,headingTwoCommand,headingThreeCommand,dictationToggleCommand};