import { LazyImport, LazyAction, LazyBootModule } from 'owa-bundling-light';

export const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "DarkModeUtils" */ './lazyIndex'),
    { name: 'DarkModeUtils' }
);

export const lazyTransformElementForDarkMode = new LazyImport(
    lazyModule,
    m => m.transformElementForDarkMode
);
export const lazyGetStylesForDarkMode = new LazyImport(lazyModule, m => m.getStylesForDarkMode);
export const lazyFixContrast = new LazyAction(lazyModule, m => m.fixContrast);

export type { AlteredElement } from './utils/transformElementForDarkMode';
export type { DarkModeData } from './utils/getNewStylesForDarkMode';
