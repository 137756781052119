import { setMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { IButtonStyles } from '@fluentui/react';
import type RibbonCutCopyPasteInfo from 'owa-compose-formatcontrols-sections/lib/schema/RibbonCutCopyPasteInfo';
import {
    lazyGetAlignSections,
    lazyGetChangeCaseSections,
    lazyGetCutCopySections,
    lazyGetUndoRedoSections,
    lazyGetFontColorSections,
    lazyGetFontBackColorSections,
    lazyGetStyleSections,
    lazyGetSpacingSections,
    lazyGetTableAlignSections,
    lazyGetApplyTableBorderSections,
    lazyGetTableBorderWeightSections,
    lazyGetTableBorderColorSections,
} from 'owa-compose-formatcontrols-sections/lib/sections';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import type { RibbonViewStateInfo } from 'owa-compose-formatcontrols-sections/lib/schema/RibbonViewStateInfo';
import type { TableBorderFormatState } from 'owa-editor-ribbonplugin-store';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export function onUndoRedoMenuClicked(
    editorId: string,
    formatViewState: RibbonFormatState,
    styles: IButtonStyles | undefined,
    tabId: number | undefined
) {
    lazyGetUndoRedoSections.import().then(getUndoRedoSections => {
        setMenuDefinition(editorId, 'undoRedoMenuDefinitionStore', () => {
            return {
                sections: getUndoRedoSections(editorId, formatViewState, styles, tabId),
            };
        });
    });
}

export function onCutCopyMenuClicked(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonCutCopyPasteInfo | null,
    formatViewState: RibbonFormatState,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean
) {
    lazyGetCutCopySections.import().then(getCutCopySections => {
        setMenuDefinition(editorId, 'cutCopyMenuDefinitionStore', () => {
            return {
                sections: getCutCopySections(
                    editorId,
                    composeId,
                    retriever,
                    formatViewState,
                    styles,
                    tabId,
                    isDisabled
                ),
            };
        });
    });
}

export function onAlignMenuClicked(
    editorId: string,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean,
    source: FormattingSource,
    useContentModel: boolean
) {
    lazyGetAlignSections.import().then(getAlignSections => {
        setMenuDefinition(editorId, 'alignMenuDefinitionStore', () => {
            return {
                sections: getAlignSections(
                    editorId,
                    styles,
                    tabId,
                    isDisabled,
                    source,
                    useContentModel
                ),
            };
        });
    });
}

export function onChangeCaseMenuClicked(
    editorId: string,
    tabId: number | undefined,
    menuItemStyles: IButtonStyles | undefined,
    source: FormattingSource
) {
    lazyGetChangeCaseSections.import().then(getChangeCaseSections => {
        setMenuDefinition(editorId, 'changeCaseMenuDefinitionStore', () => {
            return {
                sections: getChangeCaseSections(editorId, tabId, menuItemStyles, source),
            };
        });
    });
}

export function onStyleMenuClicked(
    anchorId: string | number,
    editorId: string,
    selectedId: string,
    tabId: number | undefined,
    source: FormattingSource
) {
    lazyGetStyleSections.import().then(getStyleSections => {
        setMenuDefinition(editorId, 'styleMenuDefinitionStore', () => {
            return {
                sections: getStyleSections(anchorId, editorId, selectedId, tabId, source),
            };
        });
    });
}

export function onFontColorMenuClicked(
    editorId: string,
    formatViewState: RibbonFormatState,
    tabId: number | undefined,
    source: FormattingSource
) {
    lazyGetFontColorSections.import().then(getFontColorSections => {
        setMenuDefinition(editorId, 'fontColorMenuDefinitionStore', () => {
            return {
                sections: getFontColorSections(editorId, formatViewState, tabId, source),
            };
        });
    });
}

export function onFontBackColorMenuClicked(
    editorId: string,
    formatViewState: RibbonFormatState,
    tabId: number | undefined,
    source: FormattingSource
) {
    lazyGetFontBackColorSections.import().then(getFontBackColorSections => {
        setMenuDefinition(editorId, 'fontBackColorMenuDefinitionStore', () => {
            return {
                sections: getFontBackColorSections(editorId, formatViewState, tabId, source),
            };
        });
    });
}

export function onSpacingMenuClicked(
    editorId: string,
    tabId: number | undefined,
    formatViewState: RibbonFormatState,
    source: FormattingSource
) {
    lazyGetSpacingSections.import().then(getSpacingSections => {
        setMenuDefinition(editorId, 'spacingMenuDefinitionStore', () => {
            return {
                sections: getSpacingSections(editorId, tabId, formatViewState, source),
            };
        });
    });
}

export function onTableAlignMenuClicked(editorId: string, tabId: number | undefined) {
    lazyGetTableAlignSections.import().then(getTableAlignSections => {
        setMenuDefinition(editorId, 'tableAlignmentMenuDefinitionStore', () => {
            return {
                sections: getTableAlignSections(editorId, tabId),
            };
        });
    });
}

export function onTableBorderWeightMenuClicked(
    editorId: string,
    borderState: TableBorderFormatState
) {
    lazyGetTableBorderWeightSections.import().then(getTableBorderWeightSections => {
        setMenuDefinition(editorId, 'tableBorderWeightMenuDefinitionStore', () => {
            return {
                sections: getTableBorderWeightSections(borderState),
            };
        });
    });
}

export function onTableBorderColorMenuClicked(
    editorId: string,
    borderState: TableBorderFormatState
) {
    lazyGetTableBorderColorSections.import().then(getTableBorderColorSections => {
        setMenuDefinition(editorId, 'tableBorderColorMenuDefinitionStore', () => {
            return {
                sections: getTableBorderColorSections(editorId, borderState),
            };
        });
    });
}

export function onApplyTableBorderMenuClicked(
    editorId: string,
    tabId: number | undefined,
    retriever: (composeId: string) => RibbonViewStateInfo | null,
    composeId: string
) {
    lazyGetApplyTableBorderSections.import().then(getApplyTableBorderSections => {
        setMenuDefinition(editorId, 'applyTableBorderMenuDefinitionStore', () => {
            return {
                sections: getApplyTableBorderSections(editorId, tabId, retriever, composeId),
            };
        });
    });
}
