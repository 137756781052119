export const redoSectionText = "uGVaYc";
export const cutText = "LFuX7";
export const copyControlText = "DKYcd";
export const alignLeftText = "VpOwT";
export const alignRightText = "OkbLhc";
export const alignCenterText = "nxV3cc";
export const uppercaseText = "ZYsxyc";
export const lowercaseText = "iHH9t";
export const capitalizeEachWordText = "ctQtbc";
export const sentenceCaseText = "kXHhMc";
export const fontColorMenuText = "c9m_Kc";
export const moreColorsMenuText = "fYirt";
export const customColorsText = "oGYj_c";
export const noColorText = "bffL4c";
export const highlightColorMenuText = "RyjIec";
export const normalStyleName = "HoSwUd";
export const h1StyleName = "Zq01ud";
export const h2StyleName = "sGoN3b";
export const h3StyleName = "NVOyA";
export const blockQuoteMenuText = "JeftAb";
export const stylePickerMenuText = "jtAGp";
export const spacingAddSpaceBefore = "bz8fvc";
export const spacingRemoveSpaceBefore = "ehlOzb";
export const spacingRemoveSpaceAfter = "F9nyBc";
export const spacingAddSpaceAfter = "c7lsMd";
export const borderColor = "rjco6b";
export const borderWeight = "KGBPob";
export const borderLineStyle = "fc05me";
export const borderNoOutline = "nSnqy";
export const sizeBestFit = "Cih6kc";
export const sizeSmall = "iDB8Lc";
export const sizeMedium = "C3SPBb";
export const sizeFull = "yuas8b";
export const outerShadowTitle = "z2aUzc";
export const noShadow = "NNy1Ic";
export const tableBorderBottom = "vO9lLe";
export const tableBorderTop = "TLsbvc";
export const tableBorderLeft = "nUQq6d";
export const tableBorderRight = "q7UqJc";
export const tableBorderNone = "owVa$";
export const tableBorderAll = "DFU6jc";
export const tableBorderOutside = "Z0pakc";
export const tableBorderInside = "GrWNF";
export const alignJustifyText = "eUbiDb";
export default {redoSectionText,cutText,copyControlText,alignLeftText,alignRightText,alignCenterText,uppercaseText,lowercaseText,capitalizeEachWordText,sentenceCaseText,fontColorMenuText,moreColorsMenuText,customColorsText,noColorText,highlightColorMenuText,normalStyleName,h1StyleName,h2StyleName,h3StyleName,blockQuoteMenuText,stylePickerMenuText,spacingAddSpaceBefore,spacingRemoveSpaceBefore,spacingRemoveSpaceAfter,spacingAddSpaceAfter,borderColor,borderWeight,borderLineStyle,borderNoOutline,sizeBestFit,sizeSmall,sizeMedium,sizeFull,outerShadowTitle,noShadow,tableBorderBottom,tableBorderTop,tableBorderLeft,tableBorderRight,tableBorderNone,tableBorderAll,tableBorderOutside,tableBorderInside,alignJustifyText};