import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppSplitButton } from 'owa-command-ribbon';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/AppButton';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Link from 'owa-fluent-icons-svg/lib/icons/LinkRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { onLinkMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import { showLinkDialogButtonDescription } from '../strings/hoverTooltipDesc.locstring.json';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getLinkUnlink = owaComputedFn(
    (
        editorId: string,
        showInsertDialogCallback: (parameter: AppButtonOnExecuteParameter | undefined) => void,
        tabId: number | undefined,
        styles: IButtonStyles | undefined,
        formatViewState: RibbonFormatState,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        const controlText = loc(Strings.insertLink_Button);
        const storeName = 'linkMenuDefinitionStore';
        return createAppSplitButton(
            getComposeRibbonId(7025, editorId),
            controlText,
            getControlMenuStore(editorId, storeName),
            showInsertDialogCallback,
            tabId ? constructKeytip([tabId], 7025, true /* hasMenu */) : undefined,
            Link,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(
                    Strings.insertLink_Button,
                    showLinkDialogButtonDescription,
                    getHoverTooltipHotkey(624)
                ),
                primaryDisabled:
                    formatViewState.isMultilineSelection ||
                    formatViewState.hasReadonlyContent ||
                    isDisabled,
                disabled: isDisabled,
                onMenuButtonExecute: () => {
                    onLinkMenuClicked(editorId, tabId, styles, formatViewState, isDisabled, source);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
                splitButtonAriaLabel: controlText,
            }
        );
    }
);
