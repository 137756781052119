import type { RibbonId } from 'owa-ribbon-ids';
import { constructKeytip } from './keytipUtils';

export const getKeytipForItemMenu = (
    tabId: RibbonId | undefined,
    menuId: RibbonId | undefined,
    controlId: RibbonId | undefined
) => {
    if (controlId && tabId) {
        const prefixArray: RibbonId[] = [];
        prefixArray.push(tabId);
        if (menuId) {
            prefixArray.push(menuId);
        }
        return constructKeytip(prefixArray, controlId);
    }

    return undefined;
};
