import type {
    AppComboBoxOnExecuteParameter,
    AppComboBoxPreviewOnExecuteParameter,
    AppComboBoxOption,
    AppComboBoxItem,
} from '@1js/acui-combobox';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { lazyUpdateSecondClassState, lazyUpdateFontSize } from 'owa-editor-ribbonplugin-store';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { setFontSizeCommand, getFormatStateCommand } from 'owa-editor-command';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getStore } from 'owa-fonts/lib/store/store';
import { getFontSizeFromSizeOrIndex } from 'owa-fonts/lib/utils/FontSize';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { comboBoxOptionHovered, comboBoxChecked } from './fontPicker.scss';

import {
    startLivePreview,
    stopLivePreview,
} from 'owa-compose-formatcontrols-sections/lib/utils/livePreviewHandler';
import { constructKeytip } from 'owa-command-ribbon';
import { fontSizeText } from './formatControlStrings.locstring.json';
import { fontSizeDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getFontPickerStyles, getCaretDownButtonStyles } from './fontPicker';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

const DEFAULT_FONT_SIZE = 3;
const MIN_FONT_SIZE = 8;
const MAX_FONT_SIZE = 1000;

export const getFontSizeOptions = (): AppComboBoxOption[] => {
    const viewState = getStore().fontSize;
    const appComboBoxOptions: AppComboBoxOption[] = [];
    viewState.items.forEach(item => {
        appComboBoxOptions.push({
            type: 'AppComboBoxItem',
            key: item.selectValue,
            text: item.displayValue,
            ariaLabel: item.displayValue,
            styles: {
                rootHovered: comboBoxOptionHovered,
                rootChecked: comboBoxChecked,
            },
        });
    });
    return appComboBoxOptions;
};

const isValidFontSize = (fontSize: string): boolean => {
    const intFont = parseInt(fontSize);
    return !!intFont && intFont >= MIN_FONT_SIZE && intFont <= MAX_FONT_SIZE;
};

function getSelectedKey(
    currentFontSizeName: string,
    fontSizeOptions: AppComboBoxOption[]
): string | undefined {
    let selectedKey: string | undefined = undefined;
    for (let i = 0; i < fontSizeOptions.length; i++) {
        const item = fontSizeOptions[i] as AppComboBoxItem;
        if (currentFontSizeName === item.text) {
            selectedKey = item.key;
            break;
        }
    }
    return selectedKey;
}

export const getFontSizePickerControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        tabId: number | undefined,
        isDisabled: boolean,
        defaultValue: number | undefined,
        source: FormattingSource
    ): RibbonControlProps => {
        const defaultFontSize = getFontSizeFromSizeOrIndex(
            defaultValue ?? DEFAULT_FONT_SIZE
        ).toString();
        const fontSizeLabel = loc(fontSizeText);
        const selectedKey: string | undefined = formatViewState.fontSize
            ? getSelectedKey(formatViewState.fontSize, getFontSizeOptions())
            : undefined;
        return {
            type: 'AppComboBoxProps',
            id: getComposeRibbonId((6028).toString(), editorId).toString(),
            allowFreeForm: true,
            autoComplete: false,
            label: fontSizeLabel,
            width: 64,
            options: getFontSizeOptions(),
            defaultSelectedKey: defaultFontSize,
            selectedKey,
            value: formatViewState.fontSize || '',
            onExecute: async (param: AppComboBoxOnExecuteParameter) => {
                stopLivePreview(editorId);
                const newFontSize =
                    typeof param.newValue === 'string'
                        ? param.newValue.toString()
                        : param.newValue['key'];

                const floatFont = parseFloat(newFontSize);
                if (floatFont && floatFont % 0.5 == 0) {
                    const ftSize = Math.max(
                        Math.min(floatFont, MAX_FONT_SIZE),
                        MIN_FONT_SIZE
                    ).toString();
                    await setFontSizeCommand(editorId, ftSize + 'pt', source);
                    const format = await getFormatStateCommand(editorId);
                    lazyUpdateSecondClassState.importAndExecute(formatViewState, format);
                }
                // reset to original size for invalid value
                else {
                    const originalSize = (formatViewState.fontSize || defaultFontSize).toString();
                    await lazyUpdateFontSize.importAndExecute(formatViewState, newFontSize);
                    await lazyUpdateFontSize.importAndExecute(formatViewState, originalSize);
                }
            },
            onPreviewExecute: (param: AppComboBoxPreviewOnExecuteParameter) => {
                const newFontSize =
                    typeof param.newValue === 'string'
                        ? param.newValue.toString()
                        : param.newValue['key'];

                startLivePreview(editorId, () => {
                    if (isValidFontSize(newFontSize)) {
                        setFontSizeCommand(editorId, newFontSize + 'pt', source);
                    }
                });
            },
            onAfterMenuDismiss: () => {
                callEditorApi(editorId, 'focus');
            },
            pinToPreviousComboBox: true,
            caretDownButtonStyles: getCaretDownButtonStyles(),
            styles: getFontPickerStyles(true /* isFontSize */),
            onRevertPreviewExecute: () => {
                stopLivePreview(editorId);
            },
            keytipProps: tabId ? constructKeytip([tabId], 6028) : undefined,
            disabled: isDisabled,
            customTooltip: getHoverTooltip(fontSizeText, fontSizeDesc),
        };
    }
);
