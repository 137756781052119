import { owaComputedFn } from 'owa-computed-fn';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { IButtonStyles } from '@fluentui/react';
import type { RibbonFormatState } from 'owa-editor-ribbonplugin-store';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import loc from 'owa-localize';
import { editTableCommand } from 'owa-editor-command';
import {
    tableControlMenuSplitHorizontally,
    tableControlMenuSplitVertically,
    tableControlMenuDeleteRow,
    tableControlMenuDeleteColumn,
    tableControlMenuDeleteTable,
    tableControlMenuMerge,
    tableControlMenuMergeAbove,
    tableControlMenuMergeBelow,
    tableControlMenuMergeLeft,
    tableControlMenuMergeRight,
    tableControlMenuInsertAbove,
    tableControlMenuInsertBelow,
    tableControlMenuInsertLeft,
    tableControlMenuInsertRight,
} from './tableControlsStrings.locstring.json';
import { type TableOperation } from 'roosterjs-editor-types/lib/enum/TableOperation';
import { getKeytipForItemMenu } from 'owa-command-ribbon/lib/util/Keytips/getKeytipForItemMenu';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { createAppButton } from 'owa-command-ribbon';
import type { ResourceId } from 'owa-localize';

const onTableOperation = (
    editorId: string,
    tableOperation: TableOperation | undefined,
    afterExecuteCallback: (() => void) | undefined
) => {
    if (tableOperation !== undefined) {
        editTableCommand(editorId, tableOperation);
        afterExecuteCallback?.();
    }
};

export const getTableOperationControl = owaComputedFn(
    (
        editorId: string,
        controlId: FormatControlId,
        formatViewState: RibbonFormatState | undefined,
        styles: IButtonStyles | undefined,
        icon: string | undefined,
        afterExecuteCallback: (() => void) | undefined,
        tabId: number | undefined,
        menuId: number | undefined
    ): RibbonControlProps => {
        let label: ResourceId = '';
        let tableOperation: TableOperation | undefined = undefined;
        let disabled = false;
        switch (controlId) {
            case 6058:
                label = tableControlMenuInsertAbove;
                tableOperation = 0;
                break;
            case 6059:
                label = tableControlMenuInsertBelow;
                tableOperation = 1;
                break;
            case 6060:
                label = tableControlMenuInsertLeft;
                tableOperation = 2;
                break;
            case 6061:
                label = tableControlMenuInsertRight;
                tableOperation = 3;
                break;
            case 6045:
                label = tableControlMenuDeleteTable;
                tableOperation = 4;
                break;
            case 6043:
                label = tableControlMenuDeleteColumn;
                tableOperation = 5;
                break;
            case 6044:
                label = tableControlMenuDeleteRow;
                tableOperation = 6;
                break;
            case 6050:
                label = tableControlMenuMergeAbove;
                tableOperation = 7;
                break;
            case 6051:
                label = tableControlMenuMergeBelow;
                tableOperation = 8;
                break;
            case 6052:
                label = tableControlMenuMergeLeft;
                tableOperation = 9;
                break;
            case 6053:
                label = tableControlMenuMergeRight;
                tableOperation = 10;
                break;
            case 6055:
                label = tableControlMenuSplitHorizontally;
                tableOperation = 12;
                break;
            case 6056:
                label = tableControlMenuSplitVertically;
                tableOperation = 13;
                break;
            case 6049:
                label = tableControlMenuMerge;
                tableOperation = 11;
                disabled = !formatViewState?.canMergeTableCell;
                break;
        }

        const flyoutAnchor = createAppButton(
            getComposeRibbonId(controlId, editorId),
            loc(label),
            getComputedCallback(
                controlId,
                onTableOperation,
                editorId,
                tableOperation,
                afterExecuteCallback
            ),
            getKeytipForItemMenu(tabId, menuId, controlId),
            icon,
            undefined /**iconColor */,
            styles,
            {
                disabled,
            }
        );

        return flyoutAnchor;
    }
);
