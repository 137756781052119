export const COPILOT_TRANSPARENCY_LINK = 'https://aka.ms/outlookcopilottransparency';

export const COPILOT_DRAFT_ENTITY_NAMES = [
    'CopilotCoach',
    'DraftCopilot',
    'MailRibbonDraftCopilotPreinsert',
    'CIQDraftCopilotPreinsert',
    'CIQGhostTextDraftCopilotPreinsert',
    'MailComposeBottomDraftCopilotPreinsert',
    'AddisonDraftCopilotPreinsert',
    'CalendarRibbonDraftCopilotPreinsert',
    'CoachCTADraftCopilotPreinsert',
    'DraftCopilotHighlightRewrite',
] as const;

export const COPILOT_REPLIES_ENTITY_ID = 'data-copilot-container';
export const COPILOT_ADDIN_TITLE_ID = 'data-copilot-addin-titleid';
export const RemoveDraftCopilotEntityChangeSource = 'RemoveDraftCopilotEntity';
