import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { RIBBON_OPENED_SOURCE } from 'owa-expressions-store/lib/store/schema/ExpressionPickerOpenedSource';
import isExpressionPaneOpen from 'owa-expressions-store/lib/utils/isExpressionPaneOpen';
import { lazyToggleExpressionPane } from 'owa-expressions-view';
import EmojiRegular from 'owa-fluent-icons-svg/lib/icons/EmojiRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { RibbonEmojiInfo } from '../schema/RibbonEditingInfo';
import { emojiText } from '../strings/actionControlStrings.locstring.json';
import { emojiButtonDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

const onToggleExpressionPicker = (
    composeId: string,
    retriever: (composeId: string) => RibbonEmojiInfo | null
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const { editorViewState, expressionId, targetWindow } = editingInfo;
        const shouldShowExpressionPane = !isExpressionPaneOpen(expressionId);

        lazyToggleExpressionPane.import().then(toggleExpressionPane => {
            toggleExpressionPane(
                shouldShowExpressionPane,
                true /* useFlexPane */,
                RIBBON_OPENED_SOURCE,
                editorViewState,
                expressionId,
                targetWindow
            );
        });
    }
};

export const getAddEmoji = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonEmojiInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean,
        iconColor: string | undefined
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(639, editorId),
            loc(emojiText),
            getComputedCallback(639, onToggleExpressionPicker, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 639) : undefined,
            EmojiRegular,
            iconColor,
            styles,
            {
                customTooltip: getHoverTooltip(emojiText, emojiButtonDesc),
                disabled: isDisabled,
            }
        );
    }
);
