import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { fontColor, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'TextColorPickerIcon';

registerIcons({
    icons: {
        TextColorPickerIcon: <span className={classNames(fontColor, fontIcon)}></span>,
    },
});
