import { formatTableCommand } from 'owa-editor-command';
import type { TableFormatState } from 'owa-editor-ribbonplugin-store';

export default function applyTableFormat(
    property: 'hasHeaderRow' | 'hasFirstColumn' | 'hasBandedColumns' | 'hasBandedRows',
    editorId: string,
    format: TableFormatState | undefined
) {
    if (!format) {
        return;
    }

    format[property] = !format[property];
    return formatTableCommand(editorId, format);
}
