import type { HotkeyCommand } from 'owa-hotkeys';
import { getCommands } from 'owa-compose-hotkeys';
import { convertHotkeyToReadableText } from 'owa-readable-hotkeys/lib/utils/convertHotkeyToReadableText';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';

let controlMap: Map<RibbonControlId | string, HotkeyCommand> | undefined = undefined;

export const getControlHotkeyMap = () => {
    if (controlMap === undefined) {
        const commands = getCommands();
        controlMap = new Map<RibbonControlId | string, HotkeyCommand>()
            .set(6014, commands.undoAction)
            .set(6015, commands.redoAction)
            .set(6011, commands.paste)
            .set(6009, commands.cut)
            .set(6010, commands.copy)
            .set(6001, commands.bold)
            .set(6002, commands.italic)
            .set(6003, commands.underline)
            .set(6005, commands.bullets)
            .set(6006, commands.numbering)
            .set(6030, commands.clearFormat)
            .set(624, commands.link)
            .set('heading 1', commands.headingOne)
            .set('heading 2', commands.headingTwo)
            .set('heading 3', commands.headingThree)
            .set(7029, commands.dictationToggle);
    }

    return controlMap;
};

export function getHoverTooltipHotkey(controlId: RibbonControlId | string): string | undefined {
    const hotkeySet: HotkeyCommand | undefined = controlMap?.get(controlId);
    return hotkeySet ? convertHotkeyToReadableText(hotkeySet) : undefined;
}
