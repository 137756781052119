import { action } from 'satcheljs';
export const addChatPrompt = action(
    'addChatPrompt',
    (prompt: string, shouldExecuteQuery?: boolean) => {
        return {
            prompt,
            shouldExecuteQuery,
        };
    }
);
