import { FluentButton } from 'owa-fluent-v9-shims';
import { customColorsText } from '../formatControlMenuStrings.locstring.json';
import { ColorPicker } from '@fluentui/react/lib/ColorPicker';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import type { IColor } from '@fluentui/react';
import { getColorFromString } from '@fluentui/react';
import { useWindow, WindowProvider } from '@fluentui/react/lib/WindowProvider';
import Color from 'color';
import { lazyFixContrast } from 'owa-dark-mode-utilities';
import loc from 'owa-localize';
import { cancelButton } from 'owa-locstrings/lib/strings/cancelbutton.locstring.json';
import { okButton } from 'owa-locstrings/lib/strings/okbutton.locstring.json';
import React from 'react';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Avoid deprecated APIs, use portals instead of rendering to a new root
 *	> 'unmountComponentAtNode' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root
 *	> 'render' import from 'owa-react-dom' is restricted. Use react portals instead of rendering into a new root */
import { unmountComponentAtNode, render } from 'owa-react-dom';
import type { ModeIndependentColor } from 'roosterjs-editor-types';
import { getIsDarkTheme } from 'owa-fabric-theme';

const CustomizedColorPickerDialog_Container_Id = 'CustomizedColorPickerDialog';
const BASE_WHITE = '#ffffff';
const DARK_WHITE = '#333333';

const CustomColorPicker = function CustomColorPicker(props: {
    initColor: string;
    onSelect: (color: ModeIndependentColor) => void;
}) {
    const color = React.useRef<Color>(Color(props.initColor));

    const onChange = React.useCallback((_: any, newColor: IColor) => {
        color.current = Color(newColor.str);
    }, []);

    const onOk = React.useCallback(() => {
        const bgColor = getIsDarkTheme() ? DARK_WHITE : BASE_WHITE;
        lazyFixContrast
            .importAndExecute(
                Color(color.current),
                Color(bgColor),
                true /*useSimpleMethod*/,
                bgColor
            )
            .then(darkColor => {
                props.onSelect?.({
                    lightModeColor: color.current.rgb().toString(),
                    darkModeColor: darkColor.rgb().toString(),
                });
                onDismiss();
            });
    }, []);

    const window = useWindow();

    const onDismiss = React.useCallback(() => {
        const div = window?.document.getElementById(CustomizedColorPickerDialog_Container_Id);
        if (div) {
            unmountComponentAtNode(div);
            div.parentNode?.removeChild(div);
        }
    }, []);

    const dialogContentProps = React.useMemo(
        () => ({
            title: loc(customColorsText),
        }),
        [customColorsText]
    );

    return (
        <Dialog hidden={false} dialogContentProps={dialogContentProps} onDismiss={onDismiss}>
            <ColorPicker
                color={getColorFromString(props.initColor) || 'none'}
                onChange={onChange}
                alphaType={'none'}
                showPreview={true}
            />
            <DialogFooter>
                <FluentButton appearance="primary" onClick={onOk}>
                    {loc(okButton)}
                </FluentButton>
                <FluentButton onClick={onDismiss}>{loc(cancelButton)}</FluentButton>
            </DialogFooter>
        </Dialog>
    );
};

export default function showCustomColorPicker(
    initColor: string,
    onSelect: (color: ModeIndependentColor) => void,
    targetWindow: Window
) {
    targetWindow = targetWindow || window;
    const document = targetWindow.document;
    let div = document.getElementById(CustomizedColorPickerDialog_Container_Id);
    if (!div) {
        div = document.createElement('div');
        div.id = CustomizedColorPickerDialog_Container_Id;
        document.body.appendChild(div);
    }

    render(
        <>
            <WindowProvider window={targetWindow}>
                <CustomColorPicker initColor={initColor} onSelect={onSelect} />
            </WindowProvider>
        </>,
        div
    );
}
