import { getCellShadingCells, getCellShadingColor } from './cellShadingColors';
import CellShading from '../../fonts/ShadingIcon';
import { cellShading } from './tableControlsStrings.locstring.json';
import showCustomColorPicker from 'owa-compose-formatcontrols-sections/lib/sections/CustomColorPicker';
import { moreColorsText, noColorCell } from '../formatControlStrings.locstring.json';
import type { AppColorPickerOnExecuteParameter, AppColorPickerProps } from '@1js/acui-color-picker';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { lazySetLastCellShadeColor } from 'owa-editor-ribbonplugin-store';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { applyCellShadingCommand } from 'owa-editor-command';
import MoreColor from 'owa-fluent-icons-svg/lib/icons/ColorRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { createAppSplitButton, constructKeytip, createAppButton } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

let colorId: string = '';
const NoColorCode = {
    lightModeColor: '',
    darkModeColor: '',
};

const onShadeMoreColorExecute = (editorId: string, formatViewState: RibbonFormatState) => {
    const targetWindow = callEditorApi(editorId, 'getDocument')?.defaultView || window;
    const lightModeColor = formatViewState.lastCellShadeColors.lightModeColor;
    colorId = '';
    showCustomColorPicker(
        lightModeColor,
        color => {
            applyCellShadingCommand(editorId, color);
            lazySetLastCellShadeColor.importAndExecute(formatViewState, color);
        },
        targetWindow
    );
};

export const getCellShadingControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        tabId: number | undefined,
        styles: IButtonStyles | undefined
    ): RibbonControlProps => {
        const lastCellShadeColors = formatViewState.lastCellShadeColors;
        const lightModeColor = formatViewState.lastCellShadeColors.lightModeColor;

        return createAppSplitButton(
            getComposeRibbonId(6047, editorId),
            loc(cellShading),
            {
                sections: [
                    {
                        title: loc(cellShading),
                        controls: [
                            <AppColorPickerProps>{
                                id: getComposeRibbonId(6047, editorId),
                                type: 'AppColorPickerProps',
                                columnCount: 6,
                                selectedId: colorId,
                                colorCells: getCellShadingCells(),
                                onExecute: (colorItem: AppColorPickerOnExecuteParameter) => {
                                    if (colorItem) {
                                        colorId = colorItem.selectedColorId ?? '';
                                        stopLivePreview(editorId);
                                        const colorCode = getCellShadingColor(
                                            colorItem.selectedColorId as string
                                        );
                                        applyCellShadingCommand(
                                            editorId,
                                            colorCode,
                                            true /*logColor*/
                                        );
                                        lazySetLastCellShadeColor.importAndExecute(
                                            formatViewState,
                                            colorCode
                                        );
                                    }
                                },
                                onFocus: (colorItem: AppColorPickerOnExecuteParameter) => {
                                    startLivePreview(editorId, () => {
                                        const colorCode = getCellShadingColor(
                                            colorItem.selectedColorId as string
                                        );
                                        applyCellShadingCommand(editorId, colorCode);
                                    });
                                },
                            },
                        ],
                    },
                    {
                        controls: [
                            createAppButton(
                                getComposeRibbonId(6078, editorId),
                                loc(noColorCell),
                                () => {
                                    stopLivePreview(editorId);
                                    applyCellShadingCommand(
                                        editorId,
                                        NoColorCode,
                                        true /*logColor*/
                                    );
                                    lazySetLastCellShadeColor.importAndExecute(
                                        formatViewState,
                                        NoColorCode
                                    );
                                },
                                tabId ? constructKeytip([tabId, 6047], 6032) : undefined,
                                undefined /* icon */,
                                undefined /* iconColor*/,
                                undefined /* styles*/,
                                {
                                    onFocus: () => {
                                        startLivePreview(editorId, () => {
                                            applyCellShadingCommand(editorId, NoColorCode);
                                        });
                                    },
                                } /*overrides*/
                            ),
                        ],
                    },
                    {
                        controls: [
                            createAppButton(
                                getComposeRibbonId(6074, editorId),
                                loc(moreColorsText),
                                getComputedCallback(
                                    6074,
                                    onShadeMoreColorExecute,
                                    editorId,
                                    formatViewState
                                ),
                                tabId ? constructKeytip([tabId, 6047], 6031) : undefined,
                                MoreColor
                            ),
                        ],
                    },
                ],
            },
            () => applyCellShadingCommand(editorId, lastCellShadeColors),
            tabId ? constructKeytip([tabId], 6047, true /* hasMenu */) : undefined,
            CellShading,
            lightModeColor || 'transparent' /* iconColor */,
            styles,
            {
                onAfterMenuDismiss: () => {
                    stopLivePreview(editorId);
                },
            }
        );
    }
);

const startLivePreview = (editorId: string, onPreview: () => void) => {
    const isInShadowEdit = callEditorApi(editorId, 'isInShadowEdit');

    if (!isInShadowEdit) {
        callEditorApi(editorId, 'startShadowEdit');
    }

    onPreview();
};

const stopLivePreview = (editorId: string) => {
    callEditorApi(editorId, 'stopShadowEdit');
};
