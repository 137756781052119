export const lightBlueColor = "tWf5Bc";
export const lightGreenColor = "mpyfee";
export const lightYellowColor = "HeWHZd";
export const lightOrangeColor = "z5miKd";
export const lightRedColor = "kDFe6";
export const lightPurpleColor = "t9Tqwd";
export const blueColor = "R9gk4b";
export const greenColor = "_I_0je";
export const yellowColor = "zbuucd";
export const orangeColor = "r2W6Yc";
export const redColor = "WdmxBe";
export const purpleColor = "l6rdLc";
export const darkBlueColor = "Tzcvod";
export const darkGreenColor = "ivamgd";
export const darkYellowColor = "tPsDFb";
export const darkOrangeColor = "lEVdqb";
export const darkRedColor = "k92EJd";
export const darkPurpleColor = "fIqmcb";
export const darkerBlueColor = "i9FgIe";
export const darkerGreenColor = "NkGVPe";
export const darkerYellowColor = "aICWVd";
export const darkerOrangeColor = "Uw5wGd";
export const darkerRedColor = "H_oCnc";
export const darkerPurpleColor = "OAAFsd";
export const whiteColor = "a3uAj";
export const lightGrayColor = "cmFvlc";
export const grayColor = "AzGMNb";
export const darkGrayColor = "C1BX9c";
export const darkerGrayColor = "Ty5Ire";
export const blackColor = "QNoGQe";
export const cyanColor = "kX5NMc";
export const magentaColor = "CTA5Od";
export const lightCyanColor = "OJ4wkd";
export const lightMagentaColor = "ecqgNb";
export const noColor = "sOfHYd";
export const moreColor = "kgZvhe";
export default {lightBlueColor,lightGreenColor,lightYellowColor,lightOrangeColor,lightRedColor,lightPurpleColor,blueColor,greenColor,yellowColor,orangeColor,redColor,purpleColor,darkBlueColor,darkGreenColor,darkYellowColor,darkOrangeColor,darkRedColor,darkPurpleColor,darkerBlueColor,darkerGreenColor,darkerYellowColor,darkerOrangeColor,darkerRedColor,darkerPurpleColor,whiteColor,lightGrayColor,grayColor,darkGrayColor,darkerGrayColor,blackColor,cyanColor,magentaColor,lightCyanColor,lightMagentaColor,noColor,moreColor};