// extracted by mini-css-extract-plugin
export var bullets = "kFACy";
export var fontBackColor = "IpjO_";
export var fontColor = "rEp5q";
export var fontIcon = "_x5Fe";
export var indent = "Ax3AE";
export var numbering = "lrhoT";
export var outdent = "EHzGr";
export var shading = "ovzdD";
export var subscript = "U0oqf";
export var superscript = "CcHXT";