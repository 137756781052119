export const RIBBON_OPENED_SOURCE = 'ribbon';
export const SMART_SUGGESTIONS_OPENED_SOURCE = 'smartSuggestions';
export const INLINE_SEARCH_OPENED_SOURCE = 'inlineSearch';
export const CONTEXTUAL_SEARCH_OPENED_SOURCE = 'contextualSearch';
export const CALENDAR_OPENED_SOURCE = 'calendar';

type ExpressionPickerOpenedSource =
    | 'ribbon'
    | 'smartSuggestions'
    | 'inlineSearch'
    | 'contextualSearch'
    | 'calendar';

export default ExpressionPickerOpenedSource;
