import Highlight from '../fonts/BackColorPickerIcon';
import { highlightColorText } from './formatControlStrings.locstring.json';
import { stopLivePreview } from 'owa-compose-formatcontrols-sections/lib/utils/livePreviewHandler';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { setBackgroundColorCommand } from 'owa-editor-command';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { createAppSplitButton, constructKeytip } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onFontBackColorMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getFontBackColorPickerControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        tabId: number | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        const lastBackColors = formatViewState.lastBackColors;
        const lightModeColor = formatViewState.lastBackColors.lightModeColor;
        const locHighlightColorText = loc(highlightColorText);
        const storeName = 'fontBackColorMenuDefinitionStore';
        return createAppSplitButton(
            getComposeRibbonId(6026, editorId),
            locHighlightColorText,
            getControlMenuStore(editorId, storeName),
            getComputedCallback(6026, setBackgroundColorCommand, editorId, lastBackColors, source),
            tabId ? constructKeytip([tabId], 6026, true /* hasMenu */) : undefined,
            Highlight,
            lightModeColor || 'transparent' /* iconColor */,
            styles,
            {
                onAfterMenuDismiss: () => {
                    stopLivePreview(editorId);
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
                splitButtonAriaLabel: locHighlightColorText,
                disabled: isDisabled,
                customTooltip: locHighlightColorText,
                onMenuButtonExecute: () => {
                    onFontBackColorMenuClicked(editorId, formatViewState, tabId, source);
                },
            }
        );
    }
);
