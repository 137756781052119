import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { logMailComposeSaveSend } from 'owa-mail-compose-logging';

export const startLivePreview = (editorId: string, onPreview: () => void) => {
    const isInShadowEdit = callEditorApi(editorId, 'isInShadowEdit');
    // If editor is already in shadow edit, no need to check again.
    // And the check result may be incorrect because the content is changed from last shadow edit and the cached selection path won't apply
    const range = !isInShadowEdit && callEditorApi(editorId, 'getSelectionRangeEx');

    if (isInShadowEdit || (range && !range.areAllCollapsed)) {
        callEditorApi(editorId, 'startShadowEdit');
        logMailComposeSaveSend(editorId, {}, 'startShadowEdit');

        onPreview();
    }
};

export const stopLivePreview = (editorId: string) => {
    callEditorApi(editorId, 'stopShadowEdit');
    logMailComposeSaveSend(editorId, {}, 'stopShadowEdit');
};
