import loc from 'owa-localize';
import strings from './getHoverTooltip.locstring.json';

import type { ResourceId } from 'owa-localize';

export function getHoverTooltip(
    controlName: ResourceId,
    description: ResourceId | undefined,
    hotkey?: string
) {
    let tooltip = loc(controlName);

    if (hotkey) {
        tooltip = loc(strings.hoverTooltipWithHotkey, tooltip, hotkey);
    }

    if (description) {
        tooltip = loc(strings.hoverTooltipWithDescription, tooltip, loc(description));
    }

    return tooltip;
}
