import type { CopilotElaborateEntryPoint } from '../types/CopilotElaborateEntryPoint';

export interface MountCopilotComponentPlugin {
    mountCopilotComponent: (entryPoint: CopilotElaborateEntryPoint, addinId?: string) => void;
}

type MountFunctionType = MountCopilotComponentPlugin['mountCopilotComponent'];

const editorIdToFuncMap = new Map<string, MountFunctionType>();

export function setEditorIdToFunc(id: string, func: MountFunctionType) {
    editorIdToFuncMap.set(id, func);
}

export function getFuncFromEditorId(id: string) {
    return editorIdToFuncMap.get(id);
}

export function deleteFuncForEditorId(id: string) {
    editorIdToFuncMap.delete(id);
}
