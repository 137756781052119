import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { shading, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'ShadingIcon';

registerIcons({
    icons: {
        ShadingIcon: <span className={classNames(shading, fontIcon)}></span>,
    },
});
