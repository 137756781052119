import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { IButtonStyles } from '@fluentui/react';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import loc from 'owa-localize';
import {
    tableControlMenuDelete,
    tableControlMenuMerge,
    tableControlMenuSplit,
    tableControlMenuInsert,
} from './tableControlsStrings.locstring.json';
import { getMenuDefinitionForControls } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/Menu/getMenuDefinitionForControls';
import { getTableOperationControl } from './getTableOperationControl';
import type { AppButtonProps } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import type { RibbonFormatState } from 'owa-editor-ribbonplugin-store';
import type { ResourceId } from 'owa-localize';

export const getTableOperationMenu = owaComputedFn(
    (
        editorId: string,
        controlId:
            | FormatControlId.DeleteTableMenu
            | FormatControlId.TableInsertMenu
            | FormatControlId.TableMergeCells
            | FormatControlId.TableSplitCellsMenu
            | FormatControlId.TableAlignCellMenu,
        formatViewState: RibbonFormatState | undefined,
        styles: IButtonStyles | undefined,
        afterExecuteCallback: (() => void) | undefined,
        icon: string | undefined,
        tabId: number | undefined
    ): RibbonControlProps => {
        let controls: FormatControlId[] = [];
        let label: ResourceId = '';
        let disabled = false;
        switch (controlId) {
            case 6042:
                label = tableControlMenuDelete;
                controls = [6045, 6044, 6043];
                break;
            case 6057:
                label = tableControlMenuInsert;
                controls = [6058, 6059, 6060, 6061];
                break;
            case 6049:
                label = tableControlMenuMerge;
                controls = [6050, 6051, 6052, 6053];
                disabled = !!formatViewState?.canMergeTableCell;
                break;
            case 6054:
                label = tableControlMenuSplit;
                controls = [6055, 6056];
                disabled = !!formatViewState?.canMergeTableCell;
                break;
        }

        return createAppFlyoutAnchor(
            getComposeRibbonId(controlId, editorId),
            loc(label),
            getMenuDefinitionForControls([
                ...controls.map(op => {
                    return getTableOperationControl(
                        editorId,
                        op,
                        undefined /* formatViewState*/,
                        styles,
                        undefined /* icon */,
                        afterExecuteCallback,
                        tabId,
                        controlId
                    ) as AppButtonProps;
                }),
            ]),
            tabId ? constructKeytip([tabId], controlId) : undefined,
            icon,
            undefined /* iconColor */,
            styles,
            { disabled }
        );
    }
);
