import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import { createStore } from 'satcheljs';
import { ObservableMap } from 'mobx';
import type { MenuItemControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/Menu';

export type MenuDefinitionStoreName =
    | 'tableMenuDefinitionStore'
    | 'linkMenuDefinitionStore'
    | 'sensitivityMenuDefinitionStore'
    | 'encryptMenuDefinitionStore'
    | 'importanceMenuDefinitionStore'
    | 'signatureMenuDefinitionStore'
    | 'loopMenuDefinitionStore'
    | 'editorMenuDefinitionStore'
    | 'undoRedoMenuDefinitionStore'
    | 'cutCopyMenuDefinitionStore'
    | 'alignMenuDefinitionStore'
    | 'changeCaseMenuDefinitionStore'
    | 'fontColorMenuDefinitionStore'
    | 'fontBackColorMenuDefinitionStore'
    | 'styleMenuDefinitionStore'
    | 'spacingMenuDefinitionStore'
    | 'dictationLanguageMenuDefinitionStore'
    | 'borderMenuDefinitionStore'
    | 'shadowMenuDefinitionStore'
    | 'pictureSizeMenuDefinitionStore'
    | 'boostMenuDefinitionStore'
    | 'tableAlignmentMenuDefinitionStore'
    | 'tableBorderWeightMenuDefinitionStore'
    | 'tableBorderColorMenuDefinitionStore'
    | 'applyTableBorderMenuDefinitionStore';

interface MenuDefinitionStoreData {
    menuDefinitions: ObservableMap<MenuDefinitionStoreName, () => MenuDefinition>;
}

interface MenuDefinitionStore {
    menuDefinitionStoreMap: ObservableMap<string, MenuDefinitionStoreData>;
}

const store = createStore<MenuDefinitionStore>('ComposeRibbonMenuDefinitionStore', {
    menuDefinitionStoreMap: new ObservableMap<string /* editorId */, MenuDefinitionStoreData>({}),
})();
export const getStore = () => store;

export const getControlMenuStore = (
    editorId: string,
    storeName: MenuDefinitionStoreName
): MenuDefinition => {
    return (
        store.menuDefinitionStoreMap.get(editorId)?.menuDefinitions.get(storeName)?.() ?? {
            sections: [
                {
                    controls: [
                        {
                            type: 'AppButtonProps',
                            id: 'controlPlaceHolder',
                            label: '',
                        } as MenuItemControlProps,
                    ],
                },
            ],
        }
    );
};
