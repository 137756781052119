import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getUserConfiguration, isShadowMailboxUser } from 'owa-session-store';

export function shouldDisableCopilot(
    isEncryptionEnabled: boolean,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        (isEncryptionEnabled && !isFeatureEnabled('mon-copilot-irm-support-draft')) ||
        mailboxInfo.type === 'ArchiveMailbox' ||
        mailboxInfo.type === 'SharedMailbox' ||
        mailboxInfo.type === 'GroupMailbox' ||
        isShadowMailboxUser(mailboxInfo) ||
        !!getUserConfiguration()?.SessionSettings?.IsExplicitLogon
    );
}
