import { logCoreUsage } from 'owa-analytics';
import { assertNever } from 'owa-assert';
import { setIsCopilotSeen, isCopilotSeen } from 'owa-copilot-drafts-store';
import type { ScenarioName } from '../types/ScenarioName';

export type DraftCopilotFeatureName = 'Elaborate' | 'Coach';

export function logDraftCopilotSeenDatapoint(
    draftCopilotFeatureName: DraftCopilotFeatureName,
    editorId: string,
    customData: {
        entryPoint: string;
        copilotHost: ScenarioName;
    }
) {
    const scenarioKey = customData.entryPoint + draftCopilotFeatureName;
    if (!isCopilotSeen(editorId, scenarioKey)) {
        if (draftCopilotFeatureName === 'Elaborate') {
            logCoreUsage('DraftCopilot_Seen', customData);
        } else if (draftCopilotFeatureName === 'Coach') {
            logCoreUsage('CoachCopilot_Seen', customData);
        } else {
            assertNever(draftCopilotFeatureName);
        }

        setIsCopilotSeen(editorId, scenarioKey);
    }
}
