import type RibbonToggleFormatControlInfo from '../schema/RibbonToggleFormatControlInfo';
import updateRibbonState from '../util/updateRibbonState';
import { toggleInlineFormatCommand } from 'owa-editor-command';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const onToggleButtonClicked = (
    editorId: string,
    composeId: string | undefined,
    apiName:
        | 'toggleBold'
        | 'toggleItalic'
        | 'toggleStrikethrough'
        | 'toggleSubscript'
        | 'toggleSuperscript'
        | 'toggleUnderline',
    retriever: ((composeId: string) => RibbonToggleFormatControlInfo | null) | undefined,
    source: FormattingSource
) => {
    toggleInlineFormatCommand(editorId, apiName, source).then(() => {
        updateRibbonState(composeId, retriever);
    });
};
