import { fontColorText } from './formatControlStrings.locstring.json';
import TextColorPickerIcon from '../fonts/TextColorPickerIcon';
import { stopLivePreview } from 'owa-compose-formatcontrols-sections/lib/utils/livePreviewHandler';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { setTextColorCommand } from 'owa-editor-command';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { createAppSplitButton, constructKeytip } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onFontColorMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getFontColorPickerControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        tabId: number | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        const lastTextColors = formatViewState.lastTextColors;
        const lightModeColor = formatViewState.lastTextColors.lightModeColor;
        const locFontColorText = loc(fontColorText);
        const storeName = 'fontColorMenuDefinitionStore';
        return createAppSplitButton(
            getComposeRibbonId(6025, editorId),
            locFontColorText,
            getControlMenuStore(editorId, storeName),
            getComputedCallback(6025, setTextColorCommand, editorId, lastTextColors, source),
            tabId ? constructKeytip([tabId], 6025, true /* hasMenu */) : undefined,
            TextColorPickerIcon,
            lightModeColor /* iconColor */,
            styles,
            {
                onAfterMenuDismiss: () => {
                    stopLivePreview(editorId);
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
                splitButtonAriaLabel: locFontColorText,
                disabled: isDisabled,
                customTooltip: locFontColorText,
                onMenuButtonExecute: () => {
                    onFontColorMenuClicked(editorId, formatViewState, tabId, source);
                },
            }
        );
    }
);
