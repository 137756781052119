import {
    undoTextCommand,
    redoCommand,
    insertLinkCommand,
    pasteCommand,
    cutCommand,
    copyCommand,
    boldCommand,
    italicCommand,
    underlineCommand,
    bulletsCommand,
    numberingCommand,
    clearFormatCommand,
    headingOneCommand,
    headingTwoCommand,
    headingThreeCommand,
    dictationToggleCommand,
} from './ComposeHotKeys.locstring.json';
import loc from 'owa-localize';
import type { HotkeyCommand, Hotkeys } from 'owa-hotkeys';

function createComposeHotkeyCommand(description: string, key: Hotkeys): HotkeyCommand {
    return {
        category: 'composeEmail',
        description,
        hotmail: key,
        yahoo: key,
        gmail: key,
        owa: key,
        outlook: key,
    };
}

export function getCommands(): {
    [index: string]: HotkeyCommand;
} {
    return {
        undoAction: createComposeHotkeyCommand(loc(undoTextCommand), 'ctrl+z'),
        redoAction: createComposeHotkeyCommand(loc(redoCommand), 'ctrl+y'),
        link: createComposeHotkeyCommand(loc(insertLinkCommand), 'ctrl+k'),
        paste: createComposeHotkeyCommand(loc(pasteCommand), 'ctrl+v'),
        cut: createComposeHotkeyCommand(loc(cutCommand), 'ctrl+x'),
        copy: createComposeHotkeyCommand(loc(copyCommand), 'ctrl+c'),
        bold: createComposeHotkeyCommand(loc(boldCommand), 'ctrl+b'),
        italic: createComposeHotkeyCommand(loc(italicCommand), 'ctrl+i'),
        underline: createComposeHotkeyCommand(loc(underlineCommand), 'ctrl+u'),
        bullets: createComposeHotkeyCommand(loc(bulletsCommand), 'ctrl+.'),
        numbering: createComposeHotkeyCommand(loc(numberingCommand), 'ctrl+/'),
        clearFormat: createComposeHotkeyCommand(loc(clearFormatCommand), 'ctrl+space'),
        headingOne: createComposeHotkeyCommand(loc(headingOneCommand), 'ctrl+alt+1'),
        headingTwo: createComposeHotkeyCommand(loc(headingTwoCommand), 'ctrl+alt+2'),
        headingThree: createComposeHotkeyCommand(loc(headingThreeCommand), 'ctrl+alt+3'),
        dictationToggle: createComposeHotkeyCommand(loc(dictationToggleCommand), 'alt+`'),
    };
}
