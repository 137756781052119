export const tableButtonDesc = "bOrLTd";
export const emojiButtonDesc = "HSjrCb";
export const picturesButtonDesc = "e_3Jsd";
export const accCheckerDescription = "aCJcF";
export const showLinkDialogButtonDescription = "V6Zk8b";
export const toggleLightViewModeDesc = "vjonLc";
export const toggleDarkViewModeDesc = "DzTv_b";
export const dictationDescription = "DGyS1b";
export const boostDescription = "HKwYyb";
export const highlightRewriteWithCopilotDescriptionTooltip = "dBrYnc";
export default {tableButtonDesc,emojiButtonDesc,picturesButtonDesc,accCheckerDescription,showLinkDialogButtonDescription,toggleLightViewModeDesc,toggleDarkViewModeDesc,dictationDescription,boostDescription,highlightRewriteWithCopilotDescriptionTooltip};