import { LazyImport, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FormatControlsSections"*/ './lazyIndex')
);

export const lazyGetUndoRedoSections = new LazyImport(lazyModule, m => m.getUndoRedoSections);
export const lazyGetCutCopySections = new LazyImport(lazyModule, m => m.getCutCopySections);
export const lazyGetAlignSections = new LazyImport(lazyModule, m => m.getAlignSections);
export const lazyGetChangeCaseSections = new LazyImport(lazyModule, m => m.getChangeCaseSections);
export const lazyGetFontColorSections = new LazyImport(lazyModule, m => m.getFontColorSections);
export const lazyGetFontBackColorSections = new LazyImport(
    lazyModule,
    m => m.getFontBackColorSections
);
export const lazyGetStyleSections = new LazyImport(lazyModule, m => m.getStyleSections);
export const lazyGetSpacingSections = new LazyImport(lazyModule, m => m.getSpacingSections);
export const lazyGetBorderSections = new LazyImport(lazyModule, m => m.getBorderSections);
export const lazyGetShadowSections = new LazyImport(lazyModule, m => m.getShadowSections);
export const lazyGetPictureSizeSections = new LazyImport(lazyModule, m => m.getPictureSizeSections);
export const lazyGetTableAlignSections = new LazyImport(lazyModule, m => m.getTableAlignSections);
export const lazyGetTableBorderWeightSections = new LazyImport(
    lazyModule,
    m => m.getTableBorderWeightSections
);
export const lazyGetTableBorderColorSections = new LazyImport(
    lazyModule,
    m => m.getTableBorderColorSections
);
export const lazyGetApplyTableBorderSections = new LazyImport(
    lazyModule,
    m => m.getApplyTableBorderSections
);
