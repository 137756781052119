import type FontPickerViewState from 'owa-fonts/lib/store/schema/FontPickerViewState';
import { mutatorAction } from 'satcheljs';

const MAX_LENGTH = 5;

const addFontMru = mutatorAction('addFontMru', (viewState: FontPickerViewState, value: string) => {
    const index = viewState.mru.indexOf(value);

    if (index >= 0) {
        viewState.mru.splice(index, 1);
    }

    viewState.mru.unshift(value);
    viewState.mru.splice(MAX_LENGTH);
});

export default addFontMru;
