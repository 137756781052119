import { lazyUpdateRibbonState } from 'owa-editor-ribbonplugin-store';
import type RibbonToggleFormatControlInfo from '../schema/RibbonToggleFormatControlInfo';

export default function updateRibbonState(
    composeId: string | undefined,
    retriever: ((composeId: string) => RibbonToggleFormatControlInfo | null) | undefined
) {
    if (!!composeId && !!retriever) {
        const editingInfo = retriever(composeId);
        if (!!editingInfo) {
            const { editorViewState, ribbonViewState } = editingInfo;
            if (editorViewState && ribbonViewState) {
                lazyUpdateRibbonState.importAndExecute(editorViewState, ribbonViewState);
            }
        }
    }
}
