import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import Underline from 'owa-fluent-icons-svg/lib/icons/TextUnderlineRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type RibbonToggleFormatControlInfo from '../schema/RibbonToggleFormatControlInfo';
import { underlineText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { onToggleButtonClicked } from '../util/commandActions';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getUnderlineControl = owaComputedFn(
    (
        editorId: string,
        composeId: string | undefined,
        retriever: ((composeId: string) => RibbonToggleFormatControlInfo | null) | undefined,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6003, editorId),
            loc(underlineText),
            getComputedCallback(
                6003,
                onToggleButtonClicked,
                editorId,
                composeId,
                'toggleUnderline',
                retriever,
                source
            ),
            !!formatViewState.isUnderline,
            tabId ? constructKeytip([tabId], 6003) : undefined,
            Underline,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    underlineText,
                    undefined /* description */,
                    getHoverTooltipHotkey(6003)
                ),
            }
        );
    }
);
