import type {
    AppComboBoxItem,
    AppComboBoxOnExecuteParameter,
    AppComboBoxOption,
    AppComboBoxPreviewOnExecuteParameter,
} from '@1js/acui-combobox';
import type { MenuOpenOnExecuteParameter } from '@1js/acui-common';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import classNames from 'owa-classnames';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import {
    startLivePreview,
    stopLivePreview,
} from 'owa-compose-formatcontrols-sections/lib/utils/livePreviewHandler';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getFormatStateCommand, setFontNameCommand } from 'owa-editor-command';
import addFontMru from 'owa-editor-ribbon-secondary-ui/lib/actions/addFontMru';
import { lazyUpdateSecondClassState, lazyUpdateFontName } from 'owa-editor-ribbonplugin-store';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { FontPickerItem } from 'owa-fonts/lib/utils/FontPickerItem';
import loadLocalFonts from 'owa-fonts/lib/utils/loadLocalFonts';
import { getStore } from 'owa-fonts/lib/store/store';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { getPalette } from 'owa-theme';
import { isBrowserFirefox, isBrowserSafari } from 'owa-user-agent/lib/userAgent';
import { fontDesc } from '../strings/hoverTooltipDesc.locstring.json';
import {
    comboBoxOptionHovered,
    firefoxScrollbarFix,
    fontPickerContainer,
    fontSizePicker,
    pickerInput,
    safariScrollbarFix,
} from './fontPicker.scss';
import { fontText } from './formatControlStrings.locstring.json';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

let dividerKey = 0;
const MRU_PREFIX = 'mru_';
const DEFAULT_FONT_NAME = 'Aptos';

function getComboBoxOption(item: FontPickerItem, isMRUItem: boolean): AppComboBoxOption {
    const keyPrefix: string = isMRUItem ? MRU_PREFIX : '';
    return item.selectValue == '-'
        ? {
              type: 'AppComboBoxDivider',
              key: 'divider_' + (dividerKey++).toString(),
          }
        : {
              type: 'AppComboBoxItem',
              key: keyPrefix + item.selectValue,
              text: item.displayValue,
              ariaLabel: item.displayValue,
              styles: {
                  optionText: { fontFamily: item.submitValue },
                  rootHovered: comboBoxOptionHovered,
              },
              commandValueId: item.submitValue,
          };
}

export const getFontOptions = (loadMruItems: boolean): AppComboBoxOption[] => {
    const viewState = getStore().fontName;
    const appComboBoxOptions: AppComboBoxOption[] = [];

    if (loadMruItems) {
        const mruItems = (viewState.mru || [])
            .map(family => viewState.items.filter(font => font.submitValue == family)[0])
            .filter(x => !!x);
        if (mruItems.length > 0) {
            mruItems.push({
                selectValue: '-',
                displayValue: '',
                submitValue: '',
            });
        }

        mruItems.forEach(item => {
            appComboBoxOptions.push(getComboBoxOption(item, true /* isMRUItem */));
        });
    }

    viewState.items.forEach(item => {
        appComboBoxOptions.push(getComboBoxOption(item, false /* isMRUItem */));
    });

    return appComboBoxOptions;
};

export const getFontPickerStyles = (isFontSize: boolean) => ({
    callout: fontPickerContainer,
    optionsContainerWrapper: classNames(
        isFontSize ? fontSizePicker : undefined,
        isBrowserSafari()
            ? safariScrollbarFix
            : isBrowserFirefox()
            ? firefoxScrollbarFix
            : undefined
    ),
    input: pickerInput,
    root: {
        backgroundColor: getPalette().neutralPrimarySurface,
        borderColor: getPalette().neutralTertiary,
        borderRadius: isFontSize ? '0 4px 4px 0' : '4px 0 0 4px',
    },
});

export const getCaretDownButtonStyles = () => ({
    icon: {
        height: 'unset',
        width: 'unset',
    },
    root: {
        backgroundColor: getPalette().neutralPrimarySurface,
    },
});

function getSelectedKey(
    currentFontName: string | undefined,
    fontDropDownOptions: AppComboBoxOption[]
): string | undefined {
    let selectedKey: string | undefined = undefined;
    for (let i = 0; i < fontDropDownOptions.length; i++) {
        const item = fontDropDownOptions[i] as AppComboBoxItem;
        // For regular fonts: fontName is passed as localized name, we compare it with item.text, which is localized name
        // For local fonts: fontName is passed as familyName/submitValue, we compare it with item.commandValueId, which is the familyName/submitValue
        if (currentFontName === item.text || currentFontName === item.commandValueId) {
            selectedKey = item.key;
            break;
        }
    }

    return selectedKey;
}

export const getFontPickerControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        tabId: number | undefined,
        isDisabled: boolean,
        defaultFontValue: string | undefined,
        source: FormattingSource
    ): RibbonControlProps => {
        const defaultFontName = defaultFontValue || DEFAULT_FONT_NAME;
        const fontDropDownOptions = getFontOptions(true /* loadMruItems */);
        const selectedKey: string | undefined = getSelectedKey(
            formatViewState.fontName,
            fontDropDownOptions
        );
        const fontLabel = loc(fontText);
        const targetWindow = callEditorApi(editorId, 'getDocument')?.defaultView || window;
        return {
            type: 'AppComboBoxProps',
            id: getComposeRibbonId((6027).toString(), editorId).toString(),
            allowFreeForm: true,
            autoComplete: true,
            label: fontLabel,
            width: 128,
            options: fontDropDownOptions,
            defaultSelectedKey: defaultFontName,
            selectedKey,
            value: formatViewState.fontName,
            shouldTakeFocus: true,
            onMenuOpen: (parameter: MenuOpenOnExecuteParameter) => {
                if (isFeatureEnabled('mon-cmp-localFonts-newFontPicker')) {
                    loadLocalFonts(targetWindow);
                }

                targetWindow.setTimeout(() => {
                    const input = targetWindow.document.getElementById(
                        `${parameter.location}-${parameter.id}-input`
                    );

                    if (input && input != targetWindow.document.activeElement) {
                        input.focus();
                    }
                });
            },
            onExecute: async (parameter: AppComboBoxOnExecuteParameter) => {
                stopLivePreview(editorId);
                const newFont =
                    typeof parameter.newValue === 'string'
                        ? parameter.newValue.toString()
                        : parameter.newValue['commandValueId'];

                if (newFont) {
                    // only apply font that exists in the font pick list
                    if (
                        fontDropDownOptions.some(
                            fontOption => newFont == (fontOption as AppComboBoxItem)?.commandValueId
                        )
                    ) {
                        await setFontNameCommand(editorId, newFont, source);
                        addFontMru(getStore().fontName, newFont);
                        const format = await getFormatStateCommand(editorId);
                        lazyUpdateSecondClassState.importAndExecute(formatViewState, format);
                    } else {
                        const originalFont = (
                            formatViewState.fontName || defaultFontName
                        ).toString();
                        await lazyUpdateFontName.importAndExecute(formatViewState, newFont);
                        await lazyUpdateFontName.importAndExecute(formatViewState, originalFont);
                    }
                }
            },
            onPreviewExecute: (parameter: AppComboBoxPreviewOnExecuteParameter) => {
                const newFont =
                    typeof parameter.newValue === 'string'
                        ? parameter.newValue.toString()
                        : parameter.newValue['commandValueId'] ?? '';

                startLivePreview(editorId, () => {
                    if (newFont) {
                        setFontNameCommand(editorId, newFont, source);
                    }
                });
            },
            onAfterMenuDismiss: () => {
                callEditorApi(editorId, 'focus');
            },
            caretDownButtonStyles: getCaretDownButtonStyles(),
            styles: getFontPickerStyles(false /* isFontSize */),
            onRevertPreviewExecute: () => {
                stopLivePreview(editorId);
            },
            keytipProps: tabId ? constructKeytip([tabId], 6027) : undefined,
            disabled: isDisabled,
            customTooltip: getHoverTooltip(fontText, fontDesc),
        };
    }
);
