export const tableStyleOptions = "xwGBDb";
export const headerRow = "BB8WWc";
export const bandedRows = "bYdL";
export const firstColumn = "s_NTi";
export const bandedColumns = "lTrl6b";
export const tableControlMenuInsert = "toofl";
export const tableControlMenuInsertAbove = "gOA8bd";
export const tableControlMenuInsertBelow = "UQTRgc";
export const tableControlMenuInsertLeft = "skkTid";
export const tableControlMenuInsertRight = "nSYs7b";
export const tableControlMenuDelete = "Nl1nEe";
export const tableControlMenuDeleteTable = "f4B49d";
export const tableControlMenuDeleteColumn = "jP37pb";
export const tableControlMenuDeleteRow = "TUKwv";
export const tableControlMenuMerge = "A4eJhd";
export const tableControlMenuMergeAbove = "JiTM7b";
export const tableControlMenuMergeBelow = "vlawab";
export const tableControlMenuMergeLeft = "RjK87";
export const tableControlMenuMergeRight = "Qmf90";
export const tableControlMenuSplit = "Efxaib";
export const tableControlMenuSplitHorizontally = "PoSD$";
export const tableControlMenuSplitVertically = "jzLVed";
export const tableControlMenuHeaderRow = "XfNumc";
export const tableControlMenuAlignCellMenu = "yGheD";
export const tableControlMenuAlignCellVertically = "AtW10";
export const tableControlMenuAlignCellHorizontally = "cCikId";
export const tableControlMenuAlignCellLeft = "_cSsve";
export const tableControlMenuAlignCellCenter = "_hEPbb";
export const tableControlMenuAlignCellRight = "z$SIMe";
export const tableControlMenuAlignCellTop = "WICVCb";
export const tableControlMenuAlignCellMiddle = "AU9Tzb";
export const tableControlMenuAlignCellBottom = "Mnneje";
export const cellShading = "GCzIq";
export const predefinedTableStyles = "CLd8Jd";
export const moreOptionsDesc = "c04uIc";
export const tableBorders = "tzQKKc";
export const tableBorderColor = "v_6Whd";
export const tableBorderNoWeight = "fL085c";
export default {tableStyleOptions,headerRow,bandedRows,firstColumn,bandedColumns,tableControlMenuInsert,tableControlMenuInsertAbove,tableControlMenuInsertBelow,tableControlMenuInsertLeft,tableControlMenuInsertRight,tableControlMenuDelete,tableControlMenuDeleteTable,tableControlMenuDeleteColumn,tableControlMenuDeleteRow,tableControlMenuMerge,tableControlMenuMergeAbove,tableControlMenuMergeBelow,tableControlMenuMergeLeft,tableControlMenuMergeRight,tableControlMenuSplit,tableControlMenuSplitHorizontally,tableControlMenuSplitVertically,tableControlMenuHeaderRow,tableControlMenuAlignCellMenu,tableControlMenuAlignCellVertically,tableControlMenuAlignCellHorizontally,tableControlMenuAlignCellLeft,tableControlMenuAlignCellCenter,tableControlMenuAlignCellRight,tableControlMenuAlignCellTop,tableControlMenuAlignCellMiddle,tableControlMenuAlignCellBottom,cellShading,predefinedTableStyles,moreOptionsDesc,tableBorders,tableBorderColor,tableBorderNoWeight};