import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import Copy from 'owa-fluent-icons-svg/lib/icons/CopyRegular';
import Cut from 'owa-fluent-icons-svg/lib/icons/CutRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { type DocumentCommand } from 'roosterjs-editor-types/lib/enum/DocumentCommand';
import type RibbonCutCopyPasteInfo from '../schema/RibbonCutCopyPasteInfo';
import { copyDesc, cutDesc } from '../hoverTooltipDesc.locstring.json';
import { copyControlText, cutText } from '../formatControlMenuStrings.locstring.json';
import type { MenuSection } from '@1js/acui-menu/lib/components/MenuDefinition';
import { isBrowserSafari } from 'owa-user-agent/lib/userAgent';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';

const onCutCopyClick = (
    isCut: boolean,
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonCutCopyPasteInfo | null
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const range = callEditorApi(editorId, 'getSelectionRangeEx');
        const doc = editingInfo.targetWindow.document;
        if (isBrowserSafari() && range) {
            callEditorApi(editorId, 'focus');
            callEditorApi(editorId, 'select', range);
        }
        doc?.execCommand(isCut ? 'cut' : 'copy');
    }
};

export const getCutCopyControl = owaComputedFn(
    (
        isCut: boolean,
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonCutCopyPasteInfo | null,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        parentControlId: number | undefined,
        isDisabled: boolean | undefined
    ): RibbonControlProps => {
        const controlId = isCut ? 6009 : 6010;
        const controlText = isCut ? cutText : copyControlText;
        if (!isDisabled) {
            const editingInfo = retriever(composeId);
            if (editingInfo && editingInfo.isPlainTextEditor) {
                isDisabled = editingInfo.targetWindow.document.getSelection()?.type !== 'Range';
            } else {
                isDisabled = !formatViewState.canCutCopy;
            }
        }

        return createAppButton(
            getComposeRibbonId(controlId, editorId),
            loc(controlText),
            getComputedCallback(controlId, onCutCopyClick, isCut, editorId, composeId, retriever),
            tabId
                ? constructKeytip(
                      parentControlId ? [tabId, parentControlId] : [tabId],
                      controlId,
                      true /* hasMenu */
                  )
                : undefined,
            isCut ? Cut : Copy,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    controlText,
                    isCut ? cutDesc : copyDesc,
                    getHoverTooltipHotkey(controlId)
                ),
            }
        );
    }
);

export default function getCutCopySections(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonCutCopyPasteInfo | null,
    formatViewState: RibbonFormatState,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean
): MenuSection[] {
    const controls = [
        getCutCopyControl(
            true /* isCut */,
            editorId,
            composeId,
            retriever,
            formatViewState,
            styles,
            tabId,
            6012,
            isDisabled
        ),
        getCutCopyControl(
            false /* isCut */,
            editorId,
            composeId,
            retriever,
            formatViewState,
            styles,
            tabId,
            6012,
            isDisabled
        ),
    ];
    return [{ controls }];
}
