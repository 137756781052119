export const formatPainterDesc = "nKBGdd";
export const pasteDesc = "gTbsKd";
export const styleDesc = "A8F7gd";
export const autoFormatOptionDesc = "Ef0l$b";
export const headerRowDesc = "QoU8gd";
export const firstColumnDesc = "BQCk8b";
export const bandedColumnDesc = "bNkfOe";
export const bandedRowDesc = "ZMKcGe";
export const fontDesc = "QbhXsd";
export const fontSizeDesc = "Ftsl7";
export default {formatPainterDesc,pasteDesc,styleDesc,autoFormatOptionDesc,headerRowDesc,firstColumnDesc,bandedColumnDesc,bandedRowDesc,fontDesc,fontSizeDesc};