import {
    lazyGetTableSections,
    lazyGetLinkUnlinkSections,
    lazyGetBoostSections,
    lazyGetDictationLanguagePickerMenuSections,
} from 'owa-compose-actioncontrols-sections/lib/sections';
import { setMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { RibbonEditingInfo } from 'owa-compose-actioncontrols-sections/lib/schema/RibbonEditingInfo';
import type { IKeytipProps } from '@fluentui/react/lib/Keytip';
import type { IButtonStyles } from '@fluentui/react';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { logUsage } from 'owa-analytics';
import type { EditorViewState } from 'owa-editor';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export function onTableMenuClicked(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonEditingInfo | null,
    keytip: IKeytipProps | undefined,
    tooltip: string,
    source: FormattingSource
) {
    lazyGetTableSections.import().then(getTableSections => {
        setMenuDefinition(editorId, 'tableMenuDefinitionStore', () => {
            return {
                sections: getTableSections(composeId, retriever, keytip, tooltip, source),
            };
        });
    });
}

export function onLinkMenuClicked(
    editorId: string,
    tabId: number | undefined,
    styles: IButtonStyles | undefined,
    formatViewState: RibbonFormatState,
    isDisabled: boolean,
    source: FormattingSource
) {
    lazyGetLinkUnlinkSections.import().then(getLinkUnlinkSections => {
        setMenuDefinition(editorId, 'linkMenuDefinitionStore', () => {
            return {
                sections: getLinkUnlinkSections(
                    editorId,
                    tabId,
                    styles,
                    formatViewState,
                    isDisabled,
                    source
                ),
            };
        });
    });
}

export function onDictationLanguageMenuClicked(editorId: string, composeId: string) {
    lazyGetDictationLanguagePickerMenuSections
        .import()
        .then(getDictationLanguagePickerMenuSections => {
            setMenuDefinition(editorId, 'dictationLanguageMenuDefinitionStore', () => {
                return {
                    sections: getDictationLanguagePickerMenuSections(composeId),
                };
            });
        });
}

export function onBoostMenuClicked(editorViewState: EditorViewState) {
    logUsage('Boost_ManualInitialOptionsOpened');
    lazyGetBoostSections.import().then(getBoostSections => {
        setMenuDefinition(editorViewState.editorId, 'boostMenuDefinitionStore', () => {
            return {
                sections: getBoostSections(editorViewState),
            };
        });
    });
}
