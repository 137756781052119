import zip from 'lodash-es/zip';
import { computed } from 'mobx';
import loc from 'owa-localize';
import type { ModeIndependentColor } from 'roosterjs-editor-types';
import ColorStrings from 'owa-color-picker/lib/utils/Colors.locstring.json';
import CellColorStrings from './cellShadingColorStrings.locstring.json';
import type { ResourceId } from 'owa-localize';

const cellShadingColorNames = [
    CellColorStrings.lighterBlueColor,
    CellColorStrings.lighterGreenColor,
    CellColorStrings.lighterYellowColor,
    CellColorStrings.lighterOrangeColor,
    CellColorStrings.lighterRedColor,
    CellColorStrings.lighterPurpleColor,
    ColorStrings.lightBlueColor,
    ColorStrings.lightGreenColor,
    ColorStrings.lightYellowColor,
    ColorStrings.lightOrangeColor,
    ColorStrings.lightRedColor,
    ColorStrings.lightPurpleColor,
    ColorStrings.blueColor,
    ColorStrings.greenColor,
    ColorStrings.yellowColor,
    ColorStrings.orangeColor,
    ColorStrings.redColor,
    ColorStrings.purpleColor,
    ColorStrings.darkBlueColor,
    ColorStrings.darkGreenColor,
    ColorStrings.darkYellowColor,
    ColorStrings.darkOrangeColor,
    ColorStrings.darkRedColor,
    ColorStrings.darkPurpleColor,
    ColorStrings.darkerBlueColor,
    ColorStrings.darkerGreenColor,
    ColorStrings.darkerYellowColor,
    ColorStrings.darkerOrangeColor,
    ColorStrings.darkerRedColor,
    ColorStrings.darkerPurpleColor,
    ColorStrings.whiteColor,
    ColorStrings.lightGrayColor,
    ColorStrings.grayColor,
    ColorStrings.darkGrayColor,
    ColorStrings.darkerGrayColor,
    ColorStrings.blackColor,
];
export const cellShadingLightColors = [
    '#cce4f6',
    '#CEE7D4',
    '#F8F2D3',
    '#F8E1D1',
    '#FBDEDD',
    '#E6E4F8',
    '#51a7f9',
    '#6fc040',
    '#f5d427',
    '#f3901d',
    '#ed5c57',
    '#b36ae2',
    '#0c64c0',
    '#0c882a',
    '#dcbe22',
    '#de6a19',
    '#c82613',
    '#763e9b',
    '#174e86',
    '#0f5c1a',
    '#c3971d',
    '#be5b17',
    '#861106',
    '#5e327c',
    '#002451',
    '#06400c',
    '#a37519',
    '#934511',
    '#570606',
    '#3b204d',
    '#ffffff',
    '#cccccc',
    '#999999',
    '#666666',
    '#333333',
    '#000000',
];
export const cellShadingDarkColors = [
    '#324957',
    '#364B3B',
    '#3F3C23',
    '#514033',
    '#543E3D',
    '#424151',
    '#0075c2',
    '#207a00',
    '#5d4d00',
    '#ab5500',
    '#df504d',
    '#ab63da',
    '#6da0ff',
    '#3da848',
    '#6d5c00',
    '#d3610c',
    '#ff6847',
    '#d394f9',
    '#93b8f9',
    '#7fc57b',
    '#946f00',
    '#de7633',
    '#ff9b7c',
    '#dea9fd',
    '#cedbff',
    '#a3da9b',
    '#b5852a',
    '#ef935c',
    '#ffc0b1',
    '#eecaff',
    '#333333',
    '#535353',
    '#777777',
    '#a0a0a0',
    '#cfcfcf',
    '#ffffff',
];

const cellShadingCells = computed(() => {
    return zip(cellShadingColorNames, cellShadingLightColors).map(
        ([colorName, lightModeTextColor]) => {
            return {
                id: colorName as ResourceId as any as string /* using ResourceId as Key is not encouraged */,
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                color: lightModeTextColor!,
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                label: loc(colorName!),
            };
        }
    );
});
export const getCellShadingCells = () => cellShadingCells.get();

const cellShadingColors = computed(() => {
    const cellShadingsSet: Record<string, ModeIndependentColor> = {};
    zip(cellShadingColorNames, cellShadingLightColors, cellShadingDarkColors).forEach(
        ([cellShadingColorName, lightColor, darkColor]) => {
            if (cellShadingColorName) {
                const colorCode: ModeIndependentColor = {
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                     *	> Forbidden non-null assertion. */
                    lightModeColor: lightColor!,
                    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                     *	> Forbidden non-null assertion. */
                    darkModeColor: darkColor!,
                };
                cellShadingsSet[
                    cellShadingColorName as ResourceId as any as string /* using ResourceId as Key is not encouraged */
                ] = colorCode;
            }
        }
    );
    return cellShadingsSet;
});

export function getCellShadingColor(colorId: string): ModeIndependentColor {
    return cellShadingColors.get()[colorId];
}
