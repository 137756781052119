import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { CopilotIconColorSuiteHeader } from './CopilotIconColorSuiteHeader';
import { createIconComponent } from 'owa-fluent-icons-svg';

export default 'CopilotIconFilled';

const CopilotIconFilled = createIconComponent({
    IconComponent: CopilotIconColorSuiteHeader,
    displayName: 'CopilotIconFilled',
});

registerIcons({
    icons: {
        CopilotIconFilled: <CopilotIconFilled />,
    },
});
