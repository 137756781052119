import { owaComputedFn } from 'owa-computed-fn';
import {
    constructKeytip,
    createAppFlyoutAnchor,
    createGeneratedMenuItem,
} from 'owa-command-ribbon';
import Checkmark from 'owa-fluent-icons-svg/lib/icons/CheckmarkFilled';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { getMenuDefinitionForControls } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/Menu';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { IButtonStyles } from '@fluentui/react';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import loc from 'owa-localize';
import {
    bandedRows,
    firstColumn,
    bandedColumns,
    headerRow,
    tableStyleOptions,
} from './tableControlsStrings.locstring.json';
import type { RibbonViewState } from 'owa-editor-ribbonplugin-store';
import type { AppControlProps } from '@1js/acui-ribbon-like';
import applyTableFormat from './applyTableFormat';
import { getKeytipForItemMenu } from 'owa-command-ribbon/lib/util/Keytips/getKeytipForItemMenu';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import {
    headerRowDesc,
    firstColumnDesc,
    bandedColumnDesc,
    bandedRowDesc,
} from '../../strings/hoverTooltipDesc.locstring.json';

export const getTableFormatStateMenu = owaComputedFn(
    (
        editorId: string,
        ribbonViewState: RibbonViewState,
        styles: IButtonStyles | undefined,
        icon: string | undefined,
        tabId: number | undefined
    ): RibbonControlProps => {
        const menuItemKeytip = (formControl: number | undefined) =>
            getKeytipForItemMenu(tabId, 6037, formControl);

        return createAppFlyoutAnchor(
            getComposeRibbonId(6037, editorId),
            loc(tableStyleOptions),
            getMenuDefinitionForControls([
                createGeneratedMenuItem(
                    getComposeRibbonId(6038, editorId) as string,
                    loc(headerRow),
                    getComputedCallback(
                        6038,
                        applyTableFormat,
                        'hasHeaderRow',
                        editorId,
                        ribbonViewState.tableFormatState
                    ),
                    menuItemKeytip(6038),
                    ribbonViewState.tableFormatState?.hasHeaderRow ? Checkmark : undefined,
                    undefined /* iconColor */,
                    styles /* styles */,
                    loc(headerRowDesc)
                ) as AppControlProps,
                createGeneratedMenuItem(
                    getComposeRibbonId(6040, editorId) as string,
                    loc(firstColumn),
                    getComputedCallback(
                        6040,
                        applyTableFormat,
                        'hasFirstColumn',
                        editorId,
                        ribbonViewState.tableFormatState
                    ),
                    menuItemKeytip(6040),
                    ribbonViewState.tableFormatState?.hasFirstColumn ? Checkmark : undefined,
                    undefined /* iconColor */,
                    styles /* styles */,
                    loc(firstColumnDesc)
                ) as AppControlProps,
                createGeneratedMenuItem(
                    getComposeRibbonId(6041, editorId) as string,
                    loc(bandedColumns),
                    getComputedCallback(
                        6041,
                        applyTableFormat,
                        'hasBandedColumns',
                        editorId,
                        ribbonViewState.tableFormatState
                    ),
                    menuItemKeytip(6041),
                    ribbonViewState.tableFormatState?.hasBandedColumns ? Checkmark : undefined,
                    undefined /* iconColor */,
                    styles /* styles */,
                    loc(bandedColumnDesc)
                ) as AppControlProps,
                createGeneratedMenuItem(
                    getComposeRibbonId(6039, editorId) as string,
                    loc(bandedRows),
                    getComputedCallback(
                        6039,
                        applyTableFormat,
                        'hasBandedRows',
                        editorId,
                        ribbonViewState.tableFormatState
                    ),
                    menuItemKeytip(6039),
                    ribbonViewState.tableFormatState?.hasBandedRows ? Checkmark : undefined,
                    undefined /* iconColor */,
                    styles /* styles */,
                    loc(bandedRowDesc)
                ) as AppControlProps,
            ]),
            tabId ? constructKeytip([tabId], 6037) : undefined,
            icon,
            undefined /* iconColor */,
            styles
        );
    }
);
