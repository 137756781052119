import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { IButtonStyles } from '@fluentui/react';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import loc from 'owa-localize';
import { tableControlMenuAlignCellMenu } from './tableControlsStrings.locstring.json';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onTableAlignMenuClicked } from '../../actions/deferredMenuDefinitionMutator';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getTableAlignmentMenu = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        icon: string | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const storeName = 'tableAlignmentMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(6087, editorId),
            loc(tableControlMenuAlignCellMenu),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6087, true /* hasMenu */) : undefined,
            icon,
            undefined /* iconColor */,
            styles,
            <Partial<RibbonFlyoutAnchorProps>>{
                disabled: isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onTableAlignMenuClicked(editorId, tabId);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
